// extracted by mini-css-extract-plugin
export var about = "home-module--about--c4360";
export var about1 = "home-module--about1--eadc0";
export var about2 = "home-module--about2--d80ea";
export var btn = "home-module--btn--45f65";
export var getintouch = "home-module--getintouch--b52ce";
export var grid = "home-module--grid--76fd9";
export var heading = "home-module--heading--77565";
export var home = "home-module--home--14a4f";
export var showreelinfo = "home-module--showreelinfo--65dc4";
export var sricontainer = "home-module--sricontainer--c0c3a";